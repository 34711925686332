<template>
    <div class="form-container">
        <div class="mb-5 factura-container position-relative">
            <div class="row justify-content-center">
                <div class="col-7">
                    <p class="text-center bold-text button-question">
                        Ingresa tu código postal
                    </p>
                    <InputCP :errorFlag="errors.cp"/>
                </div>
            </div>
            <!-- Botón para regrear de pantalla -->
            <div class="position-absolute arrow-container">
                <button class="btn" @click="onBackClick">
                    <img src="@/assets/img/arrowLeft.svg" />
                </button>
            </div>
        </div>

        <!-- Stepper con bolitas -->
        <div class="mb-4">
            <StepperBolitas :length="length" :step="step" :onClick="onClickStepper"/>
        </div>

        <!-- Botón para solicitar préstamo -->
        <div class="text-center mb-5" v-if="step == length">
            <ButtonConfirm text="Solicitar préstamo" :icon="true" :clickButton="onClickSolicitarPrestamo"/>
        </div>
    </div>
</template>

<script>
    import store from "@/store";

    import InputCP from '@/components/FormularioMexico/Inputs/InputCP.vue';
    import StepperBolitas from "@/components/FormularioMexico/Inputs/StepperBolitas.vue";
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
    

    export default {
        name: 'Form2819Step2',
        props: {
            errors: Object,
            updateStep: Function,
            onBackClick: Function,
            onClickStepper: Function,
            step: Number,
            length: Number,
            onClickSolicitarPrestamo: Function,
            validateEvent: Function,
        },
        store,
        components: {
            StepperBolitas,
            ButtonConfirm,
            InputCP
        },
        methods: {
        },
        mounted: function(){
            // Se valida y agrega el evento pregunta3_api_2819
            window.dataLayer = window.dataLayer || [];
            if(!this.validateEvent('pregunta3_api_2819')){
                window.dataLayer.push({
                    event: 'pregunta3_api_2819',
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    .arrow-container{
        top: 1rem;
        left: 1rem;
    }

    .button-question{
        font-size: 30px;
        padding-bottom: 2rem;
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
        font-weight: bold;
    }

    @include media-breakpoint-up(lg){
        .factura-container{
            padding: 4rem 1rem;
            border: solid 1px $blue-3;
            border-radius: 10px;
            margin-right: auto;
            margin-left: auto;
            max-width: 880px;
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
    }
</style>