<template>
    <div>
        <div class="bg-blue header-banner" >
            <div class="container py-5 text-white">
                <h4 class="text-center bold-text preaprobado mb-0">
                    Solicitud de préstamo 
                    <br class="d-md-none" />
                    <strong>preaprobado</strong> en proceso.
                </h4>
            </div>
        </div>
    
        <!-- Monto a prestar -->
        <div v-if="numeroestado != 2805 && numeroestado != 2809 && maxPrestar !== 0" class="bg-money text-center py-4 text-blue position-relative overflow-hidden">
            <img class="first-triangle position-absolute" src="@/assets/img/home/triangulo-naranja.svg" />
            <img class="second-triangle position-absolute" src="@/assets/img/home/triangulo-naranja-2.svg" />
            <div class="container py-4">
                <h1 class="text-center font-weight-bold primera-azul mb-0 text-blue bold-text monto">
                    <strong>{{ "$" + formatNumber(maxPrestar) }}</strong> 
                </h1>
                <p class="mb-0 text-center text-blue d-none d-lg-block">
                    (Sujeto a estudio crediticio)
                </p>
            </div>
        </div>
    
        <div class="mt-md-5 mt-3 px-5 px-lg-0">
            <div class="text-center">
                <img class="text-center img-icon" src="@/assets/img/icons/done-circle-green.svg" />
            </div>
    
            <div class="text-center mt-3 mt-md-5 regular-text">
                <p class="text-blue font-medium mb-0">Muchas gracias</p>
                <p class="text-blue font-medium">
                    La solicitud se ha recibido correctamente.
                </p>
                <p class="text-blue font-medium">
                    Un ejecutivo de Ibancar se pondrá en contacto
                    <br/> 
                    contigo para continuar con tu préstamo.
                </p>
                <p class="font-medium mt-md-5 mb-md-5 mt-5 mb-3">
                    Si deseas agilizar tu solicitud, contáctanos.
                </p>
            </div>
        </div>
    
        <!-- Sección con acciones del cliente -->
        <div class="container-fluid pt-3">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7 col-xl-9 text-center">
                    <ClienteButtons :solicitud="solicitud"/>
                </div>
            </div>
        </div>
    
        <!-- Sección de documentación -->
        <div v-if="step <= 7">
            <div class="row justify-content-center">
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-9 text-center">
                    <NuevoSectionDocumentacion />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ClienteButtons from '@/components/FormularioMexico/ClienteButtons.vue'
    import NuevoSectionDocumentacion from '@/components/FormularioMexico/NuevoSectionDocumentacion.vue'

    export default {
        name: 'ThankyouComponent',
        components: {
            ClienteButtons,
            NuevoSectionDocumentacion,
        },
        props: {
            maxPrestar: Number,
            numeroestado: Number,
            solicitud: Object,
            step: Number
        },
        methods: {
            formatNumber(number) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    
    .bg-blue{
        background-color: $blue;
    }

    .bg-money {
        background-color: transparentize($orange, .9)!important;
    }

    .text-blue{
        color: $blue;
    }
    .header-banner{
        margin-top: 120px;;
    }

    .fst-italic{
        font-style: italic;
    }    

    .first-triangle{
        bottom: -5px;
        left: 0;
        height: 75%;
    }

    .second-triangle{
        top: -5px;
        right: 0;
        height: 75%;
    }

    .img-icon{
        width: 122px;
        height: auto;
    }

    .font-medium{
        font-family: 'Montserrat';
        font-weight: 600;
    }

    .bold-text {
        font-family: 'Montserrat-Bold';
    }

    .regular-text{
        font-size: 25px;
        line-height: 30px;
    }

    .monto{
        font-family: 'Montserrat-Bold';
        font-size: 60px;
    }

    @include media-breakpoint-down(lg){
        .header-banner{
            margin-top: 120px;
        }
    }

    @include media-breakpoint-down(md){
        .first-triangle{
            height: 50%;
        }
        .second-triangle{
            height: 50%;
        }

        .header-banner{
            margin-top: 100px;
        }

        .img-icon{
            width: 100px;
            height: auto;
        }

        .regular-text{
            font-size: 16px;
            line-height: 20px;
        }

        .monto{
            font-size: 35px;
        }
    }

    @include media-breakpoint-down(sm){
        .header-banner{
            margin-top: 80px;
        }
    }
</style>