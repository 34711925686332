<template>
    <div class="bg-blue-light">
        <h1 class="text-blue bold-text text-center py-4 titulo">
            {{ titulo }}
        </h1>
    </div>
</template>

<script>
    export default {
        name: 'BlueLightBanner',
        props: {
            titulo: String
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    .bg-blue-light{
        background-color: $blue-3;
    }

    .text-blue{
        color: $blue;
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
    }

    .titulo{
        font-size: 43px;
        line-height: 60px;
        text-align: left;
    }

    @include media-breakpoint-down(md){
        .titulo{
            font-size: 20px;
            line-height: 1.1;
            text-align: left;
        }
    }
</style>