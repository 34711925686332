<template>
    <div class="d-flex justify-content-center align-items-center">
        <div v-for="(item, key) in getTotalSteps()" :key="key">
            <div class="d-flex align-items-center justify.content-center">
                <div class="bolita d-flex justify-content-center align-items-center" :class="{'active': key < step, 'inactive': key >= step}" @click="onClick(key + 1)">
                    <!-- Pasos disponibles -->
                    <span class="text-white" v-if="key < step">
                        {{ key + 1 }}
                    </span>
                </div>
                <div class="barra" :class="{'active': key < step - 1, 'inactive': key >= step - 1}" v-if="key < length - 1"/>
            </div>
        </div>
    </div>
    
</template>

<script>
    export default {
        name: 'StepperBolitas',
        props: {
            step: Number,
            length: Number,
            onClick: Function
        },
        methods: {
            // Arreglo vacio de longitud variable, de acuerdo a la cantidad de pasos
            getTotalSteps: function() {
                return new Array(this.length)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    
    .bolita{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        span {
            font-size: 10px;
            font-family: 'Montserrat-Bold';
        }

        &:hover{
            cursor: pointer;
        }

        &.inactive{
            cursor: default;
        }
    }

    .barra{
        width: 30px;
        height: 5px;
    }

    .active{
        background-color: $blue;
    }

    .inactive {
        background-color: $blue-3;
    }
</style>