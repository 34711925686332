<template>
    <p class="d-none d-lg-block text-center bold-text text-question">
        Indica los siguientes datos de tu auto
    </p>
    <div class="form-container mx-auto mb-4">
        <div class="mb-4">
            <SelectMarca :errorFlag="errors.marca" />
        </div>
        <div class="my-4">
            <SelectModelo :errorFlag="errors.modelo"/>
        </div>
        <div class="my-4">
            <SelectAnoMatriculacion :errorFlag="errors.ano" />
        </div>
        <div class="my-4">
            <InputKilometraje :forceLabel="true" :errorFlag="errors.kms" textClass="text-question"/>
        </div>
        <div class="my-4" v-if="aceptaCp">
            <InputCP :errorFlag="errors.cp"/>
        </div>
        <!-- Botones con selección de factura, esto no se muestra en desktop -->
        <div class="my-4 d-xl-none">
            <ButtonsInput
                titulo="¿Tienes la factura original?"
                :options="[{value: 'si', label: 'Sí'},{value: 'no', label: 'No'}]"
                :errorFlag="errors?.factura"
                :value="factura == 0 ? '0' : factura"
                :updateValue="setTitular"
                />
        </div>
        <!-- Los checks no están disponibles para desktop -->
        <div class="mt-4 mb-2 d-xl-none">
            <CheckAviso :errorFlag="errors.aviso"/>
        </div>
        <div class="mt-2 mb-4 d-xl-none">
            <CheckInformacion :errorFlag="errors.informacion"/>
        </div>
    </div>

    <!-- Texto informativo para el usuario -->
    <p class="text-center text-blue caption">
        * Si tu auto no aparece en las opciones, ponte en contacto con nosotros
        <br />
        por <a href="tel:+525555661583">llamada</a> o por <a href="https://api.whatsapp.com/send?l=es&phone=5215612673878" target="_blank">WhatsApp</a>
    </p>

    <!-- Botón naranja, cambia de texto de acuerdo al tamaño de la ventana, en caso de ser mobile, entonces el botón hace referencia a solicitar préstamo y en caso de ser desktop el texto y funcionalidad hacen referencia a continuar -->
    <div class="text-center">
        <ButtonConfirm :text="windowWidth < desktopSize ? 'Solicitar préstamo' : 'Continuar'" :icon="true" :clickButton="windowWidth < desktopSize ? onClickSolicitarPrestamo : onClickContinuar"/>
    </div>

    <p class="text-center caption mt-2 d-lg-none">
        Esta solicitud es gratuita y sin compromiso
    </p>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";

    import SelectMarca from '@/components/FormularioMexico/Inputs/SelectMarca.vue';
    import SelectModelo from '@/components/FormularioMexico/Inputs/SelectModelo.vue';
    import SelectAnoMatriculacion from '@/components/FormularioMexico/Inputs/SelectAnoMatriculacion.vue';
    import InputKilometraje from '@/components/FormularioMexico/Inputs/InputKilometraje.vue';
    import CheckAviso from '@/components/FormularioMexico/Inputs/CheckAviso.vue';
    import CheckInformacion from '@/components/FormularioMexico/Inputs/CheckInformacion.vue';
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
    import ButtonsInput from '@/components/FormularioMexico/Inputs/ButtonsInput.vue';
    import InputCP from "@/components/FormularioMexico/Inputs/InputCP.vue";

    export default {
        name: 'Form2805Step1',
        props: {
            windowWidth: Number,
            desktopSize: Number,
            errors: Object,
            onClickContinuar: Function,
            onClickSolicitarPrestamo: Function,
            aceptaCp: Boolean,
            validateEvent: Function
        },
        store,
        computed: mapState({
            factura: (state) => state?.formulario?.titular,
        }),
        components: { SelectMarca, SelectModelo, SelectAnoMatriculacion, InputKilometraje, ButtonsInput, CheckAviso, CheckInformacion, ButtonConfirm, InputCP },
        methods: {
            setTitular: function(value){
                // actualiza el valor del titular en nuestro store
                store.commit('setTitular', value)
            },
        },
        mounted: function(){
            // Se valida que el evento preguntas_api_2805 no exista 
            const eventText = 'preguntas_api_2805';
            window.dataLayer = window.dataLayer || [];
            if(!this.validateEvent(eventText)){
                window.dataLayer.push({
                    event: eventText,
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    
    .form-container{
        max-width: 500px;
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
    }

    .text-question{
        font-size: 30px;
    }

    .text-blue{
        color: $blue;
    }

    .caption{
        font-size: 15px;
        a {
            color: $blue;
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(lg){
        // Aumenta el tamaño máximo del formulario para pantallas menores a lg
        .form-container{
            width: 90%;
        }
    }
</style>