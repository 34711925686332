<template>
    <div>
        <LoaderMexico v-if="preload"/>
        <div v-else class="section-container">

            <div v-if="afiliado">
                <input type="hidden" name="idAfiliado" :value="afiliado">
            </div>

            <ThankyouComponent v-if="solicitud?.numeroestado != 2805 && solicitud?.numeroestado != 2809 && solicitud?.numeroestado != 2819" :numeroestado="typeof(solicitud?.numeroestado) === 'string' ? parseInt(solicitud?.numeroestado) : solicitud?.numeroestado" :maxPrestar="typeof(maxPrestar) === 'string' ? parseInt(maxPrestar) : maxPrestar" :step="step" :solicitud="solicitud"/>
    
            <FormEstado2805 v-if="solicitud?.numeroestado == 2805 || solicitud?.numeroestado == 2809" :solicitud="solicitud" />
    
            <FormEstado2819 v-if="solicitud?.numeroestado == 2819" :solicitud="solicitud" />
            
        </div>
    
        <div>
            <!-- Añadimos input oculto para gestion de campañas de marketing de google-->
            <input v-if="formulario?.email" id="email" type="hidden" :placeholder="formulario?.email" :value="formulario?.email">
        </div>
    </div>
    
</template>

<script>
    import axios from "axios";
    import store from "@/store";
    import { mapState } from "vuex";

    import LoaderMexico from '@/components/FormularioMexico/Loading.vue';
    import ThankyouComponent from "@/components/FormularioMexico/ThankyouComponent.vue";
    import FormEstado2805 from "@/components/FormularioMexico/FormularioApi/Form2805/FormEstado2805.vue";
    import FormEstado2819 from "@/components/FormularioMexico/FormularioApi/Form2819/FormEstado2819.vue";

    export default {
        name: 'Felicidades',
        components: {
            LoaderMexico,
            ThankyouComponent,
            FormEstado2805,
            FormEstado2819
        },
        data () {
            return {
                codigo: '',
                maxPrestar: ''
            }
        },
        methods: {
            setStep: function (step) {
                store.commit("setStep", step);
            },
            getSolicitud: async function(){
                axios.get(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/get/solicitud/web/${this.codigo}`).then(async (resp) => {
                    if(resp?.data?.data){
                        // Funcionó
                        let solicitud = resp.data.data;
                        store.commit("setSolicitud", solicitud);
                        store.commit("setAfiliado", solicitud.web);

                        store.commit('setEmail', solicitud?.def_email1)

                        if(solicitud.numeroestado >= 9000){
                            this.setStep(1);
                            this.$router.push({
                                path: "/solicitud/denegada/" + this.codigo,
                                hash: null,
                            });
                        }
                        await this.calcMaxPrestar();

                    }else{
                        store.commit("setPreload", false);
                        this.setStep(1);
                        this.$router.push({ path: "/home", hash: null });
                    }

                }).catch(() => {
                    store.commit("setPreload", false);
                    this.setStep(1);
                    this.$router.push({ path: "/home", hash: null });
                })
            },
            calcMaxPrestar: async function(){
                axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/get/propuesta/${this.solicitud.id}`).then((data) => {
                    const solicitudNecesita = this.solicitud.coche_necesita ?? 0;
                    const prestamoCliente = data?.data?.prestamo_cliente ?? 0;

                    if(solicitudNecesita == 0 && prestamoCliente){
                        // Coche necesita 0 o null
                        this.maxPrestar = prestamoCliente
                    }else{
                        this.maxPrestar = solicitudNecesita > prestamoCliente ? prestamoCliente : solicitudNecesita;
                    }
                    store.commit("setMaximoPrestamo", this.maxPrestar);
                    store.commit("setPreload", false);
                });
            },
            addEvent: function(eventText){
                window.dataLayer = window.dataLayer || [];
                const filtrado = window.dataLayer.filter(element => element?.event == eventText);
                if(filtrado.length == 0){
                    window.dataLayer.push({
                        event: eventText,
                    });
                }
            }
        },
        store,
        computed: mapState({
            step: (state) => state.formulario.step,
            formulario: (state) => state.formulario,
            solicitud: (state) => state.solicitud,
            preload: (state) => state.preload,
            afiliado: (state) => state.afiliado
        }),
        created: function () {
            store.commit("setPreload", true);
        },
        mounted: async function() {
            // Google tag manager
            if(!this.formulario?.email){
                store.commit('setEmail', this.solicitud?.cliente?.def_email1)
            }
            window.dataLayer = window.dataLayer || [];
            this.addEvent('solicitud_aceptada');

            this.codigo = this.$route.params.codigo;

            this.setStep(5)

            // Redirect to home if the code doesn't exist
            if (isNaN(this.codigo) || this.codigo == "") {
                this.setStep(1);
                this.$router.push({ path: "/", hash: null });
            }else{
                const idSolicitud = this.codigo.slice(14);
                store.commit("setTokenEnlace", this.codigo);
                store.commit("setIdSolicitud", idSolicitud);
                await this.getSolicitud()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    .section-container{
        margin-top: 140px;;
    }

    @include media-breakpoint-down(md){

        .section-container{
            margin-top: 60px;
        }
    }

    @include media-breakpoint-down(sm){
        .section-container{
            margin-top: 60px;
        }
    }
</style>