<template>
    <div class="factura-container">
        <!-- Botones para el input de factura -->
        <ButtonsInput
            titulo="¿Tienes la factura original del auto?"
            :options="[{value: 'si', label: 'Sí'},{value: 'no', label: 'No'}]"
            :errorFlag="errors?.factura"
            :value="factura === 0 ? '0' : factura"
            :updateValue="setTitular"
            />
    </div>
    <div class="form-container mx-auto">
        <!-- Checkboxes de aviso de provacidad e información -->
        <div class="mt-2 mb-2">
            <CheckAviso :errorFlag="errors.aviso"/>
        </div>
        <div class="mt-2 mb-4">
            <CheckInformacion :errorFlag="errors.informacion"/>
        </div>
        <!-- Botón que solicita préstamo -->
        <div class="text-center mb-5">
            <ButtonConfirm text="Solicitar préstamo" :icon="true" :clickButton="onClickSolicitarPrestamo"/>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";
    
    import ButtonsInput from '@/components/FormularioMexico/Inputs/ButtonsInput.vue';
    import CheckAviso from '@/components/FormularioMexico/Inputs/CheckAviso.vue';
    import CheckInformacion from '@/components/FormularioMexico/Inputs/CheckInformacion.vue';
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';

    export default {
        name: 'Form2805Step2',
        props: {
            errors: Object,
            onClickSolicitarPrestamo: Function,
            validateEvent: Function
        },
        store,
        computed: mapState({
            factura: (state) => state?.formulario?.titular,
        }),
        components: {
            ButtonsInput, CheckAviso, CheckInformacion, ButtonConfirm
        },
        methods: {
            setTitular: function(value){
                store.commit('setTitular', value)
            }
        },
        mounted: function(){
            // Se valida que el evento pregunta2_api_2805 no exista 
            const eventText = 'pregunta2_api_2805';
            window.dataLayer = window.dataLayer || [];
            if(!this.validateEvent(eventText)){
                window.dataLayer.push({
                    event: eventText,
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    .form-container{
        max-width: 500px;
    }

    .factura-container{
        padding: 4rem 1rem;
        border: solid 1px $blue-3;
        border-radius: 10px;
        margin-right: auto;
        margin-left: auto;
        max-width: 880px;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    @include media-breakpoint-down(lg){
        .form-container{
            width: 90%;
        }
    }
</style>