<template>
    <!-- Formulario para estados 2819 dónde se pregunta si tiene factura, si el auto está pagado y CP -->

    <!-- Banner reutilizable con título, subtitulo, valor y el listado. Muestra esta información y la imagen de las dos personas para desktop o una persona y su coche en mobile -->
    <SectionBanner titulo="Si tienes un auto, tienes hasta" valor="120000" :listado="['Sin vender tu auto', 'Sin dejar de mantenerlo', 'Solicítalo en 1 min']" container="banner-container"/>

    <!-- Elemento con banner de fondo azul y un texto -->
    <BlueLightBanner :titulo="`Necesitamos confirmar ${this.steps} datos`" />

    <!-- Pasos del formulario, en caso de mobile, solo tendrá un paso, en caso de desktop contará con tres pasos -->
    <div class="my-5" v-if="step === 1">
        
        <!-- Factura, auto pagado y CP (solo unas veces) -->
        <Step1 :errors="errors" :updateStep="updateStep" :windowWidth="windowWidth" :desktopSize="desktopSize"
        :onClickSolicitarPrestamo="onClickSolicitarPrestamo" :step="step" :length="steps" :onClickStepper="onClickStepper" :validateEvent="validateEvent"/>
    </div>
    
    <div class="my-3" v-if="step === 2">
        <!-- ¿Está tu auto pagado? Solo para desktop -->
        <Step2 :errors="errors" :updateStep="updateStep" :onBackClick="onBackClick" :step="step" :length="steps" :onClickStepper="onClickStepper" :onClickSolicitarPrestamo="onClickSolicitarPrestamo" :validateEvent="validateEvent"/>
    </div>

    <div class="my-3" v-if="step === 3">
        <!-- CP, solo para desktop y si la solicitud no tiene estado ni código postal -->
        <Step3 :errors="errors" :updateStep="updateStep" :onBackClick="onBackClick" :step="step" :length="steps" :onClickStepper="onClickStepper" :onClickSolicitarPrestamo="onClickSolicitarPrestamo" :validateEvent="validateEvent"/>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";
    import axios from "axios";
    // import { toRaw } from 'vue';
    
    import SectionBanner from "@/components/FormularioMexico/Sections/SectionBanner.vue";
    import BlueLightBanner from "@/components/FormularioMexico/Sections/BlueLightBanner.vue";
    import Step1 from "@/components/FormularioMexico/FormularioApi/Form2819/Step1.vue";
    import Step2 from "@/components/FormularioMexico/FormularioApi/Form2819/Step2.vue";
    import Step3 from "@/components/FormularioMexico/FormularioApi/Form2819/Step3.vue";
    
    export default {
        name: 'FormEstado2819',
        data() {
            return {
                errors: {
                    factura: false,
                    financiado: false,
                    cp: false,
                    aviso: false,
                    informacion: false
                },
                step: 1, 
                steps: 3,
                windowWidth: window.innerWidth,
                desktopSize: 1200
            }
        },
        props:{
            solicitud: Object
        },
        store,
        computed: mapState({
            factura: (state) => state?.formulario?.titular,
            financiado: (state) => state?.formulario?.financiacion,
            aviso: (state) => state?.formulario?.datos?.legal_advise,
            informacion: (state) => state?.formulario?.datos?.lopd,
            cp: (state) => state?.formulario?.codigo_postal,
            estado: (state) => state?.formulario?.estado
        }),
        methods: {
            onResize: function(){
                this.windowWidth = window.innerWidth
            },
            // valida si seleccionó alguno de los botones para 'si' y 'no' de factura y auto pagado
            validateButtons: function(value, field){
                if(value === 'si' || value === 'no'){
                    this.errors[field] = false;
                }else{
                    this.errors[field] = true;
                }
            },
            // Validación de los dos botones que existen
            validateAllButtons: function(){
                let step = null;
                this.validateButtons(this.factura, 'factura')
                this.validateButtons(this.financiado, 'financiado')
                step = this.errors.financiado ? 2 : step;
                step = this.errors.factura ? 1 : step;
                return step;
            },
            // Valida que texnga un CP válido y que esté asociado a un estado
            validateCP: function(){
                if(this.cp?.length !== 5 && (this.estado !== null || this.estado !== '')){
                    this.errors.cp = true;
                }else{
                    this.errors.cp = false;
                }
            },
            // Actualiza el paso en el que se encuentra el cliente
            updateStep: function(value){
                this.step = value;
            },
            // Función que se llama cuando el cliente termina el formulario
            onClickSolicitarPrestamo: async function(){
                // Busca si hubo un error en el botones
                const step = this.validateAllButtons();
                // Valida el CP y estado
                this.validateCP();
                // Bandera sobre los errores del CP en caso de que la solicitud no tenga estado ni CP
                const errorFlag = this.aceptaCp() ? this.errors.cp : false;
                if(this.windowWidth >= this.desktopSize){
                    if(step){
                        // Step solo se muentra si hubo un error en el paso 1 o paso 2
                        this.step = step;
                    }else{
                        // Valida la bandera sobre error en CP
                        if(errorFlag){
                            // en caso de que hubo error, manda al paso 3
                            this.step = 3;
                        }else{
                            // Llamada a la API
                            await this.solicitarPrestamo()
                        }
                    }
                }else{
                    this.errors.aviso = this.aviso !== true;
                    this.errors.informacion = this.informacion !== true;
                    if(this.errors.aviso === false && this.errors.informacion === false && step === null && errorFlag === false){
                        // Si no encuentra errores llama a la API
                        await this.solicitarPrestamo()
                    }
                }
            },
            solicitarPrestamo: async function(){
                // Activa bandera que lanza el loader
                store.commit('setPreload', true)
                // Inicialización de los datos a enviar al form
                const data = {
                    titular: this.factura,
                    financiacion: this.financiado
                }

                if(this.aceptaCp()){
                    data.cp = this.cp;
                    data.estado = this.estado;   
                }
                
                await axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/update/formulario/detalles/solicitud/${this.solicitud?.id}`, data).then(async (response) => {
                    const { errors, token_enlace, solicitud } = response?.data;
                    store.commit('setPreload', false);
                    if(errors){
                        // Este error sucede cuando hay un error de validación
                        store.commit('resetSolicitud', null)
                        this.moveToErrorPage('Hubo un error. Verifica la información proporcionada');
                    }else{           
                        await store.commit("setSolicitud", solicitud);    
                        if(token_enlace){
                            store.commit('resetSolicitud', null)
                            if (solicitud.numeroestado >= 9000) {
                                // La solicitud fue actualizada correctamente, pero se denegó por nuestro ssistema
                                store.commit("setStep", 10);
                                this.$router.push({
                                    path: "/solicitud/denegada/" + token_enlace,
                                    hash: null,
                                });
                            }else{
                                // La solicitud se actualizó correctamente, entonces se redirecciona la página del formulario con su código
                                this.$router.push({
                                    path: "/formulario/" + token_enlace,
                                    hash: null,
                                });
                            }
                        }
                    }
                }).catch((error) => {
                    // Ocurrió un error en el servidor
                    // Se manda a página de error
                    const { error: errorMessage } = error?.response?.data;
                    store.commit('setPreload', false)
                    if(errorMessage){
                        this.moveToErrorPage(errorMessage);
                    }else{
                        this.moveToErrorPage('Ocurrió un error desconocido, intenta más tarde.')
                    }
                })
            },
            // Permite al usuario retroceder en los pasos
            onBackClick: function() {
                if(this.step > 1){
                    this.step = this.step - 1;
                }
            },
            // Permite cambio de paso en el stepper con bolitas
            onClickStepper: function(value){
                if(value < this.step){
                    this.step = value;
                }
            },
            // Valida que la solicitud tenga estado o CP, en caso de no ternerlos se debe agregar el input para ser registrado
            aceptaCp: function(){
                if((this.solicitud?.def_cpostal == '' || this.solicitud?.def_cpostal == null) && (this.solicitud?.def_estado == '' || this.solicitud?.def_estado == null)){
                    return true;
                }
                return false;
            },
            // Valida si existe un evento en el datalayer, va enfocado en los eventos de google tag manager
            validateEvent: function(eventText){
                const filtrado = window.dataLayer.filter(element => element?.event == eventText);
                return filtrado.length > 0;
            },
            // Redirecciona a la página de error
            moveToErrorPage: function(text) {
                localStorage.setItem('error', text)
                this.$router.push({
                    path: "/error",
                    hash: null,
                });
            },
        },
        mounted: async function () {
            // Agrega evento que detecta el resize de los eventos y llama a la función del resize
            window.addEventListener("resize", this.onResize);
            // Inicializa la cantidad máxima de pasos permitides, tomando como base la solicitud y si esta tiene estado y cp
            this.steps = this.aceptaCp() ? 3 : 2;
        },
        watch: {
            // Detecta los cambios en el store de los valores del formulario para llamar a los validadores
            factura: function(value){
                this.validateButtons(value, 'factura')
            },
            financiado: function(value){
                this.validateButtons(value, 'financiado')
            },
            aviso: function(value){
                if(value){
                    this.errors.aviso = false;
                }else{
                    this.errors.aviso = true;
                }
            },
            informacion: function(value){
                if(value){
                    this.errors.informacion = false;
                }else{
                    this.errors.informacion = true;
                }
            },
            estado: function(){
                this.validateCP()
            }
        },
        components: { SectionBanner, BlueLightBanner, Step1, Step2, Step3 }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";


    @include media-breakpoint-up(lg){
        .banner-container {
            padding-left: 120px;
        }
    }

    @include media-breakpoint-between(md, lg){
        .banner-container{
            margin-top: 130px;
            width: 100%;
            padding-left: 5rem;
        }
    }
</style>