<template>
    <div class="container">
        <div class="card mb-3" @click="openWhatsapp()">
            <div class="row align-items-center">
                <div class="col-3 py-3">
                    <img src="@/assets/img/whatsapp.svg" alt="dinero" />
                </div>
                <div class="col-9 text-left">
                    <span class="font-weight-bold text-dark pr-2" >Enviar documentación por WhatsApp</span>
                </div>
            </div>
        </div>
        <div v-if="step==5" class="card mb-3" @click="setStep(6)">
            <div class="row align-items-center">
                <div class="col-3 py-3">
                    <img src="@/assets/img/documentacion.svg" alt="documentacion" />
                </div>
                <div class="col-9 text-left">
                    <span class="font-weight-bold text-dark pr-2">Adjuntar documentación en la web</span>
                </div>
            </div>
        </div>
        <div class="card pe-clickable mb-1">
            <a href="tel:5555661583">
                <div class="row align-items-center">
                    <div class="col-3 pt-3 pb-3">
                        <img src="@/assets/img/phone.svg" alt="phone" />
                    </div>
                    <div class="col-9 text-left">
                        <span class="font-weight-bold text-dark pr-2" >Llamar por teléfono</span>
                    </div>
                </div>
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="button-container py-4 ">
                <img  width="19" height="12" src="@/assets/img/bluearrowleft.svg" alt="ibancar">
                <a  class="text-blue" data-toggle="modal" data-target="#modalReiniciarSolicitud" >
                    <b>Reiniciar solicitud</b>
                </a> 
            </div>
        </div>
        <!-- Modal -->
        <div id="modalReiniciarSolicitud" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div id="modal-reiniciar" class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="d-flex row justify-content-center align-items-center m-3 gap-3">
                        <div class="col-12">
                            <p class="center text-center fs-18">
                                ¿Estás seguro de querer eliminar tu solicitud de préstamo?
                            </p>
                        </div>
                        <div class="col-6 ">
                            <button class="btn btn-cancelar mr-1 w-100" data-dismiss="modal">
                                No
                            </button>
                        </div>
                        <div class="col-6">
                            <button @click="reiniciarSolicitud()" class="btn btn-eliminar ml-1 w-100" data-dismiss="modal">
                                Reiniciar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import store from "@/store";
    import { mapState } from "vuex";
    export default {
        name: 'ClienteButtons',
        props: {
            solicitud: Object
        },
        store,
        methods: {
            setStep(value) {
                // Se redirecciona a la página para agregar la documentación necesaria
                this.$nextTick(function () {
                    store.commit("setStep", value);
                });
                if (value == 6) {
                    this.$router.push("confirmacion", "#documentacionNecesaria");
                }
            },
            openWhatsapp: function () {
                // Se llama al evento para Google Tag Manger y se abre whatsapp web para generar comunicación
                window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG
                window.dataLayer.push({
                    event: "click_whatsapp",
                });
                window.open("https://api.whatsapp.com/send?l=es&phone=5215612673878&text=%F0%9F%91%8B%20%C2%A1Hola!%20Prefiero%20continuar%20mi%20solicitud%20de%20pr%C3%A9stamo%20por%20WhatsApp.%20Muchas%20gracias.", "_blank");
            },
            reiniciarSolicitud() {
                // Funcionalidad que llama a la API para reiniciarl solicitud, eliminar datos de la solicitud en el store y redirecciona al home
                const idSolicitud = this.solicitud.id;
                axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/reiniciar/solicitud/${idSolicitud}`).then(() => {
                    store.commit("resetSolicitud");
                    this.$router.push({ path: "/", hash: null });
                });
            },
        },
        computed: mapState({
            step: (state) => state.formulario.step,
        }),
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    .card {
        background-color: white;
        border: 2px solid rgba(196, 196, 196, 0.4);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        &:hover{
            cursor: pointer;
        }
        a {
            &:hover{
                text-decoration: none;
            }
        }
    }
    .container{
        max-width: 445px;
    }
    .text-blue{
        color: $blue;
    }

    .button-container{
        a {
            text-decoration: underline $blue;
        }
        &:hover{
            cursor: pointer;
        }
    }
    .btn-cancelar {
        border: 1px solid $orange;
        box-sizing: border-box;
        color: $orange;
    }
    .btn-eliminar {
        background: $orange;
        color: $white;
    }
</style>