<template>
    <div class="form-container">
        <div class="mb-3 mb-lg-5 mt-md-5 factura-container">
            <ButtonsInput
                titulo="¿Tienes la factura del auto?"
                :options="[{value: 'si', label: 'Sí'},{value: 'no', label: 'No'}]"
                :errorFlag="errors?.factura"
                :value="factura === 0 ? '0' : factura"
                :updateValue="setTitular"
                />
        </div>
        <!-- Solo disponible para ventanas menores a LG -->
        <div class="my-3 my-md-5 d-xl-none">
            <ButtonsInput
                titulo="¿Está tu auto pagado?"
                :options="[{value: 'si', label: 'Sí'},{value: 'no', label: 'No'}]"
                :errorFlag="errors?.financiado"
                :value="financiado === 0 ? '0' : financiado"
                :updateValue="setFinanciado"
                />
        </div>
        <!-- Solo disponible para ventanas menores a LG y en caso de que permita agregar CP -->
        <div class="my-4 my-md-5 d-xl-none" v-if="length === 3">
            <p class="text-center bold-text button-question">
                Ingresa tu código postal
            </p>
            <div class="mx-4">
                <InputCP :errorFlag="errors.cp" errorTextClass="bold-text remove-margin"/>
            </div>
        </div>
        <div class="mt-4 mb-0 mb-md-2 d-xl-none">
            <CheckAviso :errorFlag="errors.aviso"/>
        </div>
        <div class="mt-0 mt-md-2 mb-4 d-xl-none">
            <CheckInformacion :errorFlag="errors.informacion"/>
        </div>

        <!-- Stepper con bolitas, solo disponible en desktop -->
        <div class="d-none d-xl-block">
            <StepperBolitas :length="length" :step="step" :onClick="onClickStepper"/>
        </div>

        <!-- Botón, solo disponible cuando es desktop -->
        <div class="text-center d-xl-none">
            <ButtonConfirm text="Solicitar préstamo" :icon="true" :clickButton="onClickSolicitarPrestamo" />
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";

    import ButtonsInput from '@/components/FormularioMexico/Inputs/ButtonsInput.vue';
    import InputCP from '@/components/FormularioMexico/Inputs/InputCP.vue';
    import CheckAviso from '@/components/FormularioMexico/Inputs/CheckAviso.vue';
    import CheckInformacion from '@/components/FormularioMexico/Inputs/CheckInformacion.vue';
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
    import StepperBolitas from "@/components/FormularioMexico/Inputs/StepperBolitas.vue";
    

    export default {
        name: 'Form2819Step1',
        props: {
            errors: Object,
            updateStep: Function,
            windowWidth: Number,
            desktopSize: Number,
            onClickSolicitarPrestamo: Function,
            onClickStepper: Function,
            step: Number,
            length: Number,
            validateEvent: Function
        },
        store,
        computed: mapState({
            financiado: (state) => state?.formulario?.financiacion,
            factura: (state) => state?.formulario?.titular,
        }),
        components: {
            ButtonsInput,
            CheckAviso,
            CheckInformacion,
            ButtonConfirm,
            StepperBolitas,
            InputCP
        },
        methods: {
            setFinanciado: function(value){
                store.commit('setFinanciacion', value)
            },
            setTitular: function(value){
                store.commit('setTitular', value)
                if(this.windowWidth >= this.desktopSize){
                    // En caso de que el tamaño de la ventana sea mayor a desktop, al detectar el cambio se actualiza el paso
                    this.updateStep(2)
                }
            },
        },
        mounted: function(){
            // Se valida y agrega el evento preguntas_api_2819
            window.dataLayer = window.dataLayer || [];
            if(!this.validateEvent('preguntas_api_2819')){
                window.dataLayer.push({
                    event: 'preguntas_api_2819',
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    .bold-text{
        font-family: 'Montserrat-Bold';
        font-weight: bold;
    }

    @include media-breakpoint-up(xl){
        .factura-container{
            padding: 4rem 1rem;
            border: solid 1px $blue-3;
            border-radius: 10px;
            margin-right: auto;
            margin-left: auto;
            max-width: 880px;
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
    }

    @include media-breakpoint-between(md, lg){
        .form-container{
            width: 50%;
            margin-right: auto;
            margin-left: auto;
        }
    }

    @include media-breakpoint-down(sm){
        .form-container{
            width: 80%;
            margin-right: auto;
            margin-left: auto;
        }

        .button-question{
            font-size: 18px;
            padding-bottom: 1.25rem;
        }
    }

    @include media-breakpoint-down(md){
        .button-question{
            padding-bottom: 0rem;
        }
    }
</style>