<template>

    <!-- Formulario para estados 2805 y 2809 dónde se solicitan datos del auto y se pregunta si tiene la factura -->

    <!-- Banner reutilizable con título, subtitulo, valor y el listado. Muestra esta información y la imagen de las dos personas para desktop o una persona y su coche en mobile -->
    <SectionBanner titulo="Si tienes un auto, tienes hasta" valor="120000" :listado="['Sin vender tu auto', 'Sin dejar de mantenerlo', 'Solicítalo en 1 min']" container="banner-container"/>

    <!-- Elemento con banner de fondo azul y un texto -->
    <BlueLightBanner titulo="Necesitamos completar tu solicitud" />

    <div class="my-5">
        <!-- Pasos del formulario, en caso de mobile, solo tendrá un paso, en caso de desktop contará con dos pasos -->

        <!-- Marca, modelo, año, km, si es mobile incluye el selector de factura, si la solicitud no tiene CP y estado, entonces permite agregar CP -->
        <Step1 v-if="step === 1" :windowWidth="windowWidth" :desktopSize="desktopSize" :errors="errors" :onClickContinuar="onClickContinuar" :onClickSolicitarPrestamo="onClickSolicitarPrestamo" :aceptaCp="aceptaCp()" :validateEvent="validateEvent"/>

        <!-- Solo disponible para desktop, cuenta con la pregunta de si se tiene la factura -->
        <Step2 v-if="step === 2" :errors="errors" :onClickSolicitarPrestamo="onClickSolicitarPrestamo" :validateEvent="validateEvent" />
    </div>
</template>

<script> 
    import { mapState } from "vuex";
    import store from "@/store";
    import axios from "axios";

    import SectionBanner from "@/components/FormularioMexico/Sections/SectionBanner.vue";
    import BlueLightBanner from "@/components/FormularioMexico/Sections/BlueLightBanner.vue";

    import Step1 from '@/components/FormularioMexico/FormularioApi/Form2805/Step1.vue'
    import Step2 from '@/components/FormularioMexico/FormularioApi/Form2805/Step2.vue'

    export default {
        name: 'FormEstado2805',
        data() {
            return {
                step: 1,
                errors: {
                    marca: false,
                    modelo: false,
                    ano: false,
                    kms: false,
                    factura: false,
                    aviso: false,
                    informacion: false,
                    cp: false
                },
                windowWidth: window.innerWidth,
                desktopSize: 1200
            }
        },
        props: {
            solicitud: Object
        },
        store,
        computed: mapState({
            marca: (state) => state?.formulario?.data_matricula?.marca,
            modelo: (state) => state?.formulario?.data_matricula?.modelo,
            ano: (state) => state?.formulario?.data_matricula?.fecha_matriculacion,
            kms: (state) => state?.formulario?.kms,
            factura: (state) => state?.formulario?.titular,
            aviso: (state) => state?.formulario?.datos?.legal_advise,
            informacion: (state) => state?.formulario?.datos?.lopd,
            cp: (state) => state?.formulario?.codigo_postal,
            estado: (state) => state?.formulario?.estado
        }),
        methods: {
            // Actualiza los errores para los select año, modelo y marca
            validateSelect: function(value, field){
                if(value === 0 || value === '' || value === null){
                    this.errors[field] = true;
                }else{
                    this.errors[field] = false;
                }
            },
            // Valida que el KMS sea válido
            validateKms: function(value){
                if(!(value < 1000000 && value > 0)){
                    this.errors.kms = true
                }else{
                    this.errors.kms = false
                }
            },
            // Valida el selector y botones de la factura
            validateFactura: function(value){
                if(value === 'si' || value === 'no'){
                    this.errors.factura = false;
                }else{
                    this.errors.factura = true;
                }
            },
            // Valida si fueron seleccionados los checkboxes de aviso de privacidad y tratamiento de datos
            validateCheck: function(value, field){
                if(value !== true){
                    this.errors[field] = true;
                }else{
                    this.errors[field] = false;
                }
            },
            // Valida los errores del paso 1
            validateErroresStep1: function(){
                const errorsToValidateStep1 = ['marca', 'modelo', 'ano', 'kms'];
                if(this.aceptaCp()){
                    errorsToValidateStep1.push('cp')
                }
                let flag = false;
                for( const error in this.errors){
                    if(errorsToValidateStep1.indexOf(error) !== -1)
                    flag = this.errors[error] || flag; 
                }
                return flag;
            },
            // Valida que texnga un CP válido y que esté asociado a un estado
            validateCP: function(){
                if(this.cp?.length !== 5 && (this.estado !== null || this.estado !== '')){
                    this.errors.cp = true;
                }else{
                    this.errors.cp = false;
                }
            },
            // Función a ser llamada al terminar el formulario
            onClickSolicitarPrestamo: async function() {
                // Validación de datos del formulario en el fron
                this.validateSelect(this.marca, 'marca')
                this.validateSelect(this.modelo, 'modelo')
                this.validateSelect(this.ano, 'ano')
                this.validateKms(this.kms)
                this.validateFactura(this.factura)
                this.validateCheck(this.aviso, 'aviso')
                this.validateCheck(this.informacion, 'informacion')
                if(this.aceptaCp()){
                    this.validateCP();
                }
                // Bandera que detecta que tenga al menos un error
                let flag = false;
                for( const error in this.errors){
                    flag = this.errors[error] || flag; 
                }

                if(flag){
                    if(this.step === 2){
                        // Si se encuentra en el paso dos valida si el error se encuentra en el paso 1
                        flag = this.validateErroresStep1();
                        if(flag){
                            // En caso de encontrar un error de validación del paso 1, entonces cambiar de paso activo
                            this.step = 1;
                        }
                    }
                }else{
                    // Si la bandera es negativa, entonces si puede continuar a la llamada al API
                    store.commit('setPreload', true)
                    await this.solicitarPrestamo();
                }
            },
            // Función a ser llamada para cambiar de paso
            onClickContinuar: function(){
                // Validación de elementos en el paso 1
                this.validateSelect(this.marca, 'marca')
                this.validateSelect(this.modelo, 'modelo')
                this.validateSelect(this.ano, 'ano')
                this.validateKms(this.kms)
                if(this.aceptaCp()){
                    this.validateCP();
                }
                // Si no encuentra errores en el paso 1, entonces permite cambiar de paso
                const flag = this.validateErroresStep1();
                if(!flag){
                    this.step = 2;
                }
            },
            // Lamada a la API para solicitar el prestamo (Agregar los datos faltantes a la solicitud)
            solicitarPrestamo: async function() {
                // Se genera la información a mandar
                const data = {
                    car_km: this.kms,
                    car_brand: this.marca,
                    car_model: this.modelo,
                    car_year_registration: this.ano,
                    legal_advise: this.aviso,
                    lopd: this.informacion,
                    titular: this.factura
                }

                if(this.aceptaCp()){
                    data.cp = this.cp;
                    data.estado = this.estado;   
                }
                // llamada a la API
                await axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/tasar-solicitud-web/${this.solicitud?.id}`, data).then( async (response) => {
                    const { errors, token_enlace, solicitud } = response?.data;
                    store.commit('setPreload', false)
                    if(errors){
                        // Este error sucede cuando hay un error de validación
                        store.commit('resetSolicitud', null)
                        this.moveToErrorPage('Hubo un error. Verifica la información proporcionada');
                    }else{
                        if(token_enlace){
                            await store.commit("setSolicitud", solicitud);    
                            if (solicitud.numeroestado >= 9000) {
                                store.commit('resetSolicitud', null)
                                // La solicitud fue actualizada correctamente, pero se denegó por nuestro ssistema
                                store.commit("setStep", 10);
                                this.$router.push({
                                    path: "/solicitud/denegada/" + token_enlace,
                                    hash: null,
                                });
                            }else{
                                // La solicitud se actualizó correctamente, entonces se redirecciona la página del formulario con su código
                                this.$router.push({
                                    path: "/formulario/" + token_enlace,
                                    hash: null,
                                });
                            } 
                        }
                    }
                }).catch((error) => {
                    // Ocurrió un error en el servidor
                    // Se manda a página de error
                    const { error: errorMessage } = error?.response?.data;
                    store.commit('setPreload', false)
                    if(errorMessage){
                        this.moveToErrorPage(errorMessage);
                    }else{
                        this.moveToErrorPage('Ocurrió un error desconocido, intenta más tarde.')
                    }
                })
            },
            // Valida que la solicitud tenga estado o CP, en caso de no ternerlos se debe agregar el input para ser registrado
            aceptaCp: function(){
                if((this.solicitud?.def_cpostal == '' || this.solicitud?.def_cpostal == null) && (this.solicitud?.def_estado == '' || this.solicitud?.def_estado == null)){
                    return true;
                }
                return false;
            },
            // Función que actualiza nuestra variable con el ancho de la página cuando se activo el evento onResize
            onResize: function(){
                this.windowWidth = window.innerWidth
            },
            // Valida si existe un evento en el datalayer, va enfocado en los eventos de google tag manager
            validateEvent: function(eventText){
                const filtrado = window.dataLayer.filter(element => element?.event == eventText);
                return filtrado.length > 0;
            },
            // Redirecciona a la página de error
            moveToErrorPage: function(text) {
                localStorage.setItem('error', text)
                this.$router.push({
                    path: "/error",
                    hash: null,
                });
            },
        },
        // Detecta los cambios en el store de los valores del formulario para llamar a los validadores
        watch: {
            'marca': function(value){
                this.validateSelect(value, 'marca')
            },
            'modelo': function(value){
                this.validateSelect(value, 'modelo')
            },
            'ano': function(value) {
                this.validateSelect(value, 'ano')
            },
            'kms': async function(value){
                this.validateKms(value)
            },
            'factura': function(value){
                this.validateFactura(value)
            },
            'aviso': function(value){
                if(value){
                    this.errors.aviso = false;
                }
            },
            'informacion': function(value){
                if(value){
                    this.errors.informacion = false;
                }
            },
            'estado': function(){
                this.validateCP()
            }
            
        },
        mounted: async function () {
            // Agrega evento que detecta el resize de los eventos y llama a la función del resize
            window.addEventListener("resize", this.onResize);
        },
        components: { SectionBanner, BlueLightBanner, Step1, Step2 }
    }
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";


    @include media-breakpoint-between(md, lg){
        .banner-container{
            margin-top: 130px;
            width: 100%;
            padding-left: 5rem;
        }
    }
</style>